import { getUserId, getUserName } from '../../Auth/AuthUtils';
import { IDeleteLikeRequest, IDictionary, ILike, ILikeCommentRequest, ILikePostRequest } from '../../Models/LikeModel';
import { Api } from '../Api';

export default class LikeService {
  static async createPostLike(postLike: ILikePostRequest): Promise<void> {
    const api = Api(true);
    postLike.userId = await getUserId();
    postLike.userUserName = await getUserName();
    await api.post(postLike, 'api/Like/Post');
  }

  static async createCommentLike(commentLike: ILikeCommentRequest): Promise<void> {
    const api = Api(true);
    commentLike.userId = await getUserId();
    commentLike.userUserName = await getUserName();
    await api.post(commentLike, 'api/Like/Comment');
  }

  static async getLikesByUserPostId(postId: string): Promise<IDictionary<ILike>> {
    const api = Api(true);
    const response = await api.get(`api/Like/${postId}`);
    return (await response.json()) as IDictionary<ILike>;
  }

  static async deleteLike(req: IDeleteLikeRequest): Promise<void> {
    const api = Api(true);
    await api.delete(req, 'api/Like');
  }
}
