/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Tabs, Tab, Paper } from '@mui/material';
import { isMobile } from 'react-device-detect';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import recipeIcon from '../assets/images/mellis-tips-ikon.svg';
import tipsProductIcon from '../assets/images/tips_produkter_header.svg';
import homeIcon from '../assets/images/home_1.svg';
import { TabEnum, tabs } from '../utils/RouteHelper';

type Props = {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  children: React.ReactNode;
};

export default function MenuTabs(props: Props): JSX.Element {
  const { activeTab, setActiveTab, children } = props;

  const getIcon = (tab: number): JSX.Element => {
    if (tab === TabEnum.HOME) {
      return <img alt="homeUnselected" height={40} src={homeIcon} className="menuTabIcon" />;
    }

    if (tab === TabEnum.FORUM) {
      return <ForumOutlinedIcon className="menuTabIcon" />;
    }

    if (tab === TabEnum.RECIPE) {
      return <img alt="recept" src={recipeIcon} className="menuTabIcon" />;
    }

    return <img alt="tipsProductUnselected" src={tipsProductIcon} className="menuTabIcon" />;
  };

  return (
    <>
      <Paper className="tabs--container">
        <Tabs
          variant="fullWidth"
          onChange={(_: React.ChangeEvent<unknown>, newValue: number): void => setActiveTab(newValue)}
          value={activeTab}
          indicatorColor="primary"
        >
          {tabs.map((v) => {
            return (
              <Tab
                itemID={v.tab.toString()}
                key={v.route}
                label={v.label}
                icon={getIcon(v.tabIdentifier)}
                className="menuTabs"
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  setActiveTab(v.tab);
                }}
              />
            );
          })}
        </Tabs>
      </Paper>

      <div style={{ paddingBottom: isMobile ? 30 : 0 }}>{children}</div>
    </>
  );
}
