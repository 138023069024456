/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-cycle */

import { Reducer, Action } from 'redux';
import { CategoryIdEnum } from '../../Models/Category';
import {
  ICommentModel,
  ICommentPagedResponse,
  ICommentsPagedRequest,
  ICreateCommentRequest,
  IRepliedToComment
} from '../../Models/CommentModel';
import CommentService from '../../Services/Comments/CommentService';
import { ApplicationState, AppThunkAction } from '../index';
import { PostActions } from '../Post/Post';

// eslint-disable-next-line no-shadow
export enum SetCommentActionEnum {
  REMOVECOMMENT,
  ADDCOMMENT,
  COMMENTCHANGED
}

export interface CommentState {
  comments: ICommentModel[] | null;
  totalCount: number;
  loading: boolean;
  repliedToComment: IRepliedToComment | null;
}

const getCommentsByPostId =
  (postId: string): AppThunkAction<KnownActions> =>
  async (dispatch) => {
    dispatch({
      type: 'GET_COMMENT_BY_POST_ID_REQUEST'
    });

    try {
      const response = await CommentService.getCommentsByPostId(postId);
      const data = (await response.json()) as ICommentModel[];

      dispatch({
        type: 'GET_COMMENT_BY_POST_ID_SUCCESS',
        comments: data
      });
    } catch (error) {
      dispatch({
        type: 'GET_COMMENT_BY_POST_ID_FAILED'
      });
    }
  };

const getCommentsByPostIdPaged =
  (commentRequest: ICommentsPagedRequest, renewal: boolean): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    dispatch({
      type: 'GET_COMMENTS_BY_POST_ID_PAGED_REQUEST',
      renewal
    });
    try {
      const response = await CommentService.getCommentsByPostIdPaged(commentRequest);
      const commentsResponse = (await response.json()) as ICommentPagedResponse;

      if (!renewal) {
        commentsResponse.comments =
          getState && getState().comment.comments
            ? (getState().comment.comments as ICommentModel[]).concat(commentsResponse.comments)
            : commentsResponse.comments;
      }
      dispatch({
        type: 'GET_COMMENTS_BY_POST_ID_PAGED_SUCCESS',
        comments: commentsResponse.comments,
        totalCount: commentsResponse.commentCount
      });
    } catch (error) {
      dispatch({
        type: 'GET_COMMENTS_BY_POST_ID_PAGED_FAILED'
      });
    }
  };

const createComment =
  (comment: ICreateCommentRequest, categoryId: CategoryIdEnum): AppThunkAction<any> =>
  async (dispatch) => {
    dispatch({
      type: 'CREATE_COMMENT_REQUEST'
    });

    try {
      const response = await CommentService.createComment(comment);
      const commentId = await response.text();
      PostActions.getPostById(comment.postId, categoryId)(dispatch);
      const newComment = await CommentService.getCommentById(commentId);

      dispatch({
        type: 'CREATE_COMMENT_SUCCESS',
        comment: newComment
      });
    } catch (error) {
      dispatch({
        type: 'CREATE_COMMENT_FAILED'
      });
    }
  };

const setRepliedToComment =
  (value: IRepliedToComment | null): AppThunkAction<KnownActions> =>
  (dispatch) => {
    dispatch({
      type: 'SET_REPLIED_TO_COMMENT',
      repliedToComment: value
    });
  };

const setComment =
  (value: ICommentModel, commentAction: SetCommentActionEnum): AppThunkAction<KnownActions> =>
  (dispatch, getState) => {
    const _getState = getState as () => ApplicationState;
    let comments = _getState().comment.comments as ICommentModel[];

    if (commentAction === SetCommentActionEnum.COMMENTCHANGED) {
      const existingCommentIndex = comments?.findIndex((c) => c.id === value.id) as number;
      comments[existingCommentIndex] = value;
    }
    if (commentAction === SetCommentActionEnum.ADDCOMMENT) {
      comments.push(value);
    }
    if (commentAction === SetCommentActionEnum.REMOVECOMMENT) {
      comments = comments.filter((c) => c.id !== value.id);
    }

    dispatch({
      type: 'SET_COMMENTS',
      comments
    });
  };

const setInitialComment =
  (comments: ICommentModel[], totalCount: number): AppThunkAction<KnownActions> =>
  (dispatch) => {
    dispatch({
      type: 'SET_INITIAL_COMMENTS',
      totalCount,
      comments
    });
  };

export const CommentActions = {
  getCommentsByPostId,
  getCommentsByPostIdPaged,
  createComment,
  setRepliedToComment,
  setComment,
  setInitialComment
};

const unloadedState: CommentState = {
  comments: null,
  totalCount: 0,
  loading: false,
  repliedToComment: null
};

export const reducer: Reducer<CommentState> = (
  state: CommentState | undefined,
  incomingAction: Action
): CommentState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownActions;

  switch (action.type) {
    case 'GET_COMMENT_BY_POST_ID_REQUEST':
      return { ...state, loading: true, repliedToComment: null };
    case 'GET_COMMENT_BY_POST_ID_FAILED':
      return { ...state, loading: false };
    case 'GET_COMMENT_BY_POST_ID_SUCCESS':
      return { ...state, loading: false, comments: action.comments };

    case 'GET_COMMENTS_BY_POST_ID_PAGED_REQUEST':
      return {
        ...state,
        loading: true,
        repliedToComment: null,
        comments: action.renewal ? [] : state.comments,
        totalCount: action.renewal ? 0 : state.totalCount
      };
    case 'GET_COMMENTS_BY_POST_ID_PAGED_SUCCESS':
      return { ...state, loading: false, comments: action.comments, totalCount: action.totalCount };
    case 'GET_COMMENTS_BY_POST_ID_PAGED_FAILED':
      return { ...state, loading: false };

    case 'CREATE_COMMENT_REQUEST':
      return { ...state, loading: true, repliedToComment: null };
    case 'CREATE_COMMENT_SUCCESS':
      const existingComments = state.comments as ICommentModel[];
      const comments = [...existingComments, action.comment];
      return { ...state, loading: false, comments };
    case 'CREATE_COMMENT_FAILED':
      return { ...state, loading: false };

    case 'SET_REPLIED_TO_COMMENT':
      return { ...state, repliedToComment: action.repliedToComment };

    case 'SET_COMMENTS':
      return { ...state, comments: action.comments };

    case 'SET_INITIAL_COMMENTS':
      return { ...state, comments: action.comments, totalCount: action.totalCount };

    default:
      return state;
  }
};

interface getCommentsByPostIdRequest {
  type: 'GET_COMMENT_BY_POST_ID_REQUEST';
}
interface getCommentsByPostIdSuccess {
  type: 'GET_COMMENT_BY_POST_ID_SUCCESS';
  comments: ICommentModel[];
}
interface getCommentsByPostIdFailed {
  type: 'GET_COMMENT_BY_POST_ID_FAILED';
}

interface GetCommentsByPostIdPagedRequest {
  type: 'GET_COMMENTS_BY_POST_ID_PAGED_REQUEST';
  renewal: boolean;
}
interface GetCommentsByPostIdPagedSuccess {
  type: 'GET_COMMENTS_BY_POST_ID_PAGED_SUCCESS';
  comments: ICommentModel[];
  totalCount: number;
}
interface GetCommentsByPostIdPagedFailed {
  type: 'GET_COMMENTS_BY_POST_ID_PAGED_FAILED';
}

interface CreateCommentRequest {
  type: 'CREATE_COMMENT_REQUEST';
}
interface CreateCommentSuccess {
  type: 'CREATE_COMMENT_SUCCESS';
  comment: ICommentModel;
}
interface CreateCommentFailed {
  type: 'CREATE_COMMENT_FAILED';
}

interface ActionSetRepliedToComment {
  type: 'SET_REPLIED_TO_COMMENT';
  repliedToComment: IRepliedToComment | null;
}

interface SetComments {
  type: 'SET_COMMENTS';
  comments: ICommentModel[];
}

interface SetInitialComment {
  type: 'SET_INITIAL_COMMENTS';
  comments: ICommentModel[];
  totalCount: number;
}

type KnownActions =
  | getCommentsByPostIdRequest
  | getCommentsByPostIdSuccess
  | getCommentsByPostIdFailed
  | GetCommentsByPostIdPagedRequest
  | GetCommentsByPostIdPagedSuccess
  | GetCommentsByPostIdPagedFailed
  | CreateCommentRequest
  | CreateCommentSuccess
  | CreateCommentFailed
  | ActionSetRepliedToComment
  | SetComments
  | SetInitialComment;
