import { Action, Reducer } from 'redux';
// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from '../index';
import { IDictionary, ILike } from '../../Models/LikeModel';
import LikeService from '../../Services/Likes/LikeService';

export interface LikeState {
  likes: IDictionary<ILike>;
  testedPostIds: IDictionary<string>;
}

const getLikesByUserPostId =
  (postId: string, force = false): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    try {
      const testedPostIds = getState ? getState().like.testedPostIds : {};
      if (testedPostIds[postId] && !force) return;

      if (getState && getState().like.likes[postId] && !force) return;

      const likes = await LikeService.getLikesByUserPostId(postId);
      const existingLikes = getState ? getState().like.likes : {};
      const newTestedPostId: IDictionary<string> = {};
      newTestedPostId[postId] = postId;

      const existingPostIds = getState ? getState().like.testedPostIds : {};

      dispatch({
        type: 'GET_LIKESBYUSERPOSTID_SUCCESS',
        likes: { ...existingLikes, ...likes },
        testedPostIds: { ...existingPostIds, ...newTestedPostId }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

const removeLike =
  (postId: string): AppThunkAction<KnownActions> =>
  (dispatch, getState) => {
    if (getState) {
      const likes: IDictionary<ILike> = {};
      const existingLikes = getState().like.likes;
      Object.keys(existingLikes).forEach((v) => {
        if (v !== postId) likes[v] = existingLikes[v];
      });

      dispatch({
        type: 'REMOVE_LIKE',
        likes
      });
    }
  };

export const LikeActions = {
  getLikesByUserPostId,
  removeLike
};

const unloadedState: LikeState = { likes: {}, testedPostIds: {} };

export const reducer: Reducer<LikeState> = (state: LikeState | undefined, incomingAction: Action): LikeState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownActions;

  switch (action.type) {
    case 'GET_LIKESBYUSERPOSTID_SUCCESS':
      return {
        testedPostIds: action.testedPostIds,
        likes: action.likes
      };
    case 'REMOVE_LIKE':
      return {
        ...state,
        likes: action.likes
      };
    default:
      return state;
  }
};

interface GetLikesByUserPostIdSuccess {
  type: 'GET_LIKESBYUSERPOSTID_SUCCESS';
  likes: IDictionary<ILike>;
  testedPostIds: IDictionary<string>;
}

interface RemoveLike {
  type: 'REMOVE_LIKE';
  likes: IDictionary<ILike>;
}

type KnownActions = GetLikesByUserPostIdSuccess | RemoveLike;
