export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_signupsignin',
    forgotPassword: 'B2C_1_passwordreset',
    profileEdit: 'B2C_1_profileediting'
  },
  authorities: {
    root: 'babyforum.b2clogin.com',
    signUpSignIn: {
      authority: 'https://babyforum.b2clogin.com/babyforum.onmicrosoft.com/b2c_1_signupsignin'
    },
    forgotPassword: {
      authority: 'https://babyforum.b2clogin.com/babyforum.onmicrosoft.com/B2C_1_passwordreset'
    },
    profileEditing: {
      authority: 'https://babyforum.b2clogin.com/babyforum.onmicrosoft.com/B2C_1_profileediting'
    }
  }
};
