export interface ICategory {
  value: string;
  id: CategoryIdEnum | RecipeSubCategoryIdEnum;
}

export interface ISubCategory {
  value: string;
  id: SubCategoryIdEnum;
  categoryId: SubCategoryLevel;
  slug: string;
}

export interface IRecipeSubCategory {
  value: string;
  id: RecipeSubCategoryIdEnum;
  categoryId: SubCategoryLevel;
  slug: string;
  optionId: number;
}

export interface IMaterialSubCategory {
  value: string;
  id: MaterialCategory;
  categoryId: SubCategoryLevel;
  slug: string;
}

export interface ISelectItems {
  value: string;
  id: OrderByEnum;
}

// eslint-disable-next-line no-shadow
export enum CategoryIdEnum {
  ALL = 0,
  PARENT = 1,
  PREGNANT = 2,
  RECIPE = 3,
  MATERIAL = 4
}

// eslint-disable-next-line no-shadow
export enum SubCategoryIdEnum {
  ALL = 0,
  BREASTFEEDING = 1,
  CRAVINGS = 2,
  CHILDBIRTH = 3,
  PARENTALLEAVE = 4,
  SENSITIVETOPIC = 5,
  NAMESUGGESTIONS = 6,
  NEWBORN = 7,
  PLANNINGFORKIDS = 8,
  FUNNYSTORIES = 9,
  OTHER = 10,
  FOOD = 11,
  FUNCTIONVARIATIONS = 12,
  SLEEP = 13,
  KIDS13 = 14,
  KIDS46 = 15,
  KIDS79 = 16,
  KIDS10 = 17,
  KIDS01 = 18
}

// eslint-disable-next-line no-shadow
export enum RecipeSubCategoryIdEnum {
  NONE = -1,
  BREAKFAST = 0,
  SNACK = 1,
  MEAL = 2,
  ALL = 3
}

// eslint-disable-next-line no-shadow
export enum MaterialCategory {
  NONE = -1,
  ALL = 99,
  GUIDE = 0,
  POSTER = 1,
  CALENDAR = 2,
  ARTICLE = 3,
  FOODLIST = 4,
  LEARNING = 5,
  OTHER = 6
}

// eslint-disable-next-line no-shadow
export enum SubCategoryLevel {
  BOTH = 0,
  PARENT = 1,
  PREGNANT = 2,
  RECIPE = 3
}

// eslint-disable-next-line no-shadow
export enum OrderByEnum {
  NEWEST = 1,
  POPULAR = 2
}

export const categories = [
  { value: 'Alla kategorier', id: CategoryIdEnum.ALL },
  { value: 'Föräldrar', id: CategoryIdEnum.PARENT },
  { value: 'Gravid', id: CategoryIdEnum.PREGNANT }
] as ICategory[];
export const createNewPostCategories = [
  { value: 'Föräldrar', id: CategoryIdEnum.PARENT },
  { value: 'Gravid', id: CategoryIdEnum.PREGNANT }
] as ICategory[];
export const createNewPostSubCategories = [
  { value: 'Alla kategorier', id: SubCategoryIdEnum.ALL, categoryId: SubCategoryLevel.BOTH, slug: 'alla-kategorier' },
  { value: 'Amning', id: SubCategoryIdEnum.BREASTFEEDING, categoryId: SubCategoryLevel.BOTH, slug: 'amning' },
  { value: 'Cravings', id: SubCategoryIdEnum.CRAVINGS, categoryId: SubCategoryLevel.PREGNANT, slug: 'cravings' },
  {
    value: 'Förlossning',
    id: SubCategoryIdEnum.CHILDBIRTH,
    categoryId: SubCategoryLevel.PREGNANT,
    slug: 'forlossning'
  },
  {
    value: 'Föräldraledighet',
    id: SubCategoryIdEnum.PARENTALLEAVE,
    categoryId: SubCategoryLevel.PREGNANT,
    slug: 'foraldraledighet'
  },
  {
    value: 'Känsliga ämnen',
    id: SubCategoryIdEnum.SENSITIVETOPIC,
    categoryId: SubCategoryLevel.BOTH,
    slug: 'kansliga-amnen'
  },
  {
    value: 'Namnförslag',
    id: SubCategoryIdEnum.NAMESUGGESTIONS,
    categoryId: SubCategoryLevel.PREGNANT,
    slug: 'namnforslag'
  },
  { value: 'Nyfödd', id: SubCategoryIdEnum.NEWBORN, categoryId: SubCategoryLevel.BOTH, slug: 'nyfodd' },
  {
    value: 'Planerar barn',
    id: SubCategoryIdEnum.PLANNINGFORKIDS,
    categoryId: SubCategoryLevel.PREGNANT,
    slug: 'planerar-barn'
  },
  {
    value: 'Roliga historier',
    id: SubCategoryIdEnum.FUNNYSTORIES,
    categoryId: SubCategoryLevel.PREGNANT,
    slug: 'roliga-historier'
  },
  { value: 'Mat', id: SubCategoryIdEnum.FOOD, categoryId: SubCategoryLevel.PARENT, slug: 'mat' },
  {
    value: 'Funktionsvariationer',
    id: SubCategoryIdEnum.FUNCTIONVARIATIONS,
    categoryId: SubCategoryLevel.PARENT,
    slug: 'funktionsvariationer'
  },
  { value: 'Sömn', id: SubCategoryIdEnum.SLEEP, categoryId: SubCategoryLevel.PARENT, slug: 'somn' },
  { value: 'Barn 0-1 år', id: SubCategoryIdEnum.KIDS01, categoryId: SubCategoryLevel.PARENT, slug: 'barn-0-1-ar' },
  { value: 'Barn 1-3 år', id: SubCategoryIdEnum.KIDS13, categoryId: SubCategoryLevel.PARENT, slug: 'barn-1-3-ar' },
  { value: 'Barn 4-6 år', id: SubCategoryIdEnum.KIDS46, categoryId: SubCategoryLevel.PARENT, slug: 'barn-4-6-ar' },
  { value: 'Barn 7-9 år', id: SubCategoryIdEnum.KIDS79, categoryId: SubCategoryLevel.PARENT, slug: 'barn-7-9-ar' },
  { value: 'Barn 10+ år', id: SubCategoryIdEnum.KIDS10, categoryId: SubCategoryLevel.PARENT, slug: 'barn-10-ar' },
  { value: 'Övrigt', id: SubCategoryIdEnum.OTHER, categoryId: SubCategoryLevel.BOTH, slug: 'ovrigt' }
] as ISubCategory[];

export const recipeSubCategories = [
  {
    value: 'Alla kategorier',
    id: RecipeSubCategoryIdEnum.ALL,
    categoryId: SubCategoryLevel.RECIPE,
    slug: 'alla',
    optionId: 1
  },
  {
    value: 'Mellanmål',
    id: RecipeSubCategoryIdEnum.SNACK,
    categoryId: SubCategoryLevel.RECIPE,
    slug: 'mellanmal',
    optionId: 2
  },
  {
    value: 'Måltid',
    id: RecipeSubCategoryIdEnum.MEAL,
    categoryId: SubCategoryLevel.RECIPE,
    slug: 'maltid',
    optionId: 3
  }
  // {
  //   value: 'Frukost',
  //   id: RecipeSubCategoryIdEnum.BREAKFAST,
  //   categoryId: SubCategoryLevel.RECIPE,
  //   slug: 'frukost',
  //   optionId: 4
  // }
];

export const materialSubCategories = [
  { value: 'Guide', id: MaterialCategory.GUIDE, categoryId: SubCategoryLevel.BOTH, slug: 'guide' },
  { value: 'Poster', id: MaterialCategory.POSTER, categoryId: SubCategoryLevel.BOTH, slug: 'poster' },
  { value: 'Kalender', id: MaterialCategory.CALENDAR, categoryId: SubCategoryLevel.BOTH, slug: 'kalender' },
  { value: 'Artikel', id: MaterialCategory.ARTICLE, categoryId: SubCategoryLevel.BOTH, slug: 'artikel' },
  { value: 'Matlista', id: MaterialCategory.FOODLIST, categoryId: SubCategoryLevel.BOTH, slug: 'matlista' },
  { value: 'Lärande', id: MaterialCategory.LEARNING, categoryId: SubCategoryLevel.BOTH, slug: 'larande' },
  { value: 'Övrigt', id: MaterialCategory.OTHER, categoryId: SubCategoryLevel.BOTH, slug: 'ovrigt' }
];

export const sortOrder = [
  { value: 'Nya {category}inlägg', id: OrderByEnum.NEWEST },
  { value: 'Populära {category}inlägg', id: OrderByEnum.POPULAR }
] as ISelectItems[];
