/* eslint-disable no-console */
import { Action, Reducer } from 'redux';
// eslint-disable-next-line import/no-cycle
import { ApplicationState, AppThunkAction } from '..';
import { getUserId } from '../../Auth/AuthUtils';
import { IUserProfile, IUpdateUserDecsriptionRequest, IUserPost, IGetUserPostRequest } from '../../Models/User';
import UserService from '../../Services/Users/UserService';

export interface UserState {
  userProfile: IUserProfile | null;
  myUserProfile: IUserProfile | null;
  totalCount: number | null;
  userPosts: IUserPost[];
  updateSuccess: boolean;
  isLoading: boolean;
}

const updateUserDescription =
  (description: string): AppThunkAction<KnownActions> =>
  async (dispatch) => {
    dispatch({
      type: 'UPDATE_USERDESCRIPTION_REQUEST'
    });

    try {
      const userId = await getUserId();

      await UserService.updateUserDescription({ description, userId } as IUpdateUserDecsriptionRequest);

      dispatch({
        type: 'UPDATE_USERDESCRIPTION_SUCCESS'
      });

      getMyUserProfile()(dispatch);
    } catch (error) {
      dispatch({
        type: 'UPDATE_USERDESCRIPTION_FAILED'
      });
    }
  };
const getUserProfile =
  (userId: string): AppThunkAction<KnownActions> =>
  async (dispatch) => {
    dispatch({
      type: 'GET_USERPROFILE_REQUEST'
    });

    try {
      const userProfile = await UserService.getUserProfile(userId);

      dispatch({
        type: 'GET_USERPROFILE_SUCCESS',
        userProfile
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: 'GET_USERPROFILE_FAILED',
        error
      });
    }
  };
const getUserPosts =
  (req: IGetUserPostRequest, renewal: boolean): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    dispatch({
      type: 'GET_USERPOSTS_REQUEST',
      renewal
    });

    try {
      const response = await UserService.getUserPosts(req);

      if (!renewal) {
        response.userPosts = getState ? getState().user.userPosts.concat(response.userPosts) : response.userPosts;
      }

      dispatch({
        type: 'GET_USERPOSTS_SUCCESS',
        userPosts: response.userPosts,
        totalCount: response.totalCount
      });
    } catch (error) {
      dispatch({
        type: 'GET_USERPOSTS_FAILED'
      });
    }
  };

const updateProfilePicture =
  (imageUrl: string): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_PROFILEPICTURE_REQUEST'
    });

    try {
      await UserService.updateProfilePicture(imageUrl);
      dispatch({ type: 'UPDATE_PROFILEPICTURE_SUCCESS' });

      getMyUserProfile()(dispatch);

      const userState = (getState as () => ApplicationState)().user;
      const totalCount = userState.totalCount as number;
      const userPosts = userState.userPosts.map((v) => {
        const post = { ...v };
        post.userPictureUrl = imageUrl;
        return post;
      });

      dispatch({ type: 'GET_USERPOSTS_SUCCESS', totalCount, userPosts });
    } catch (error) {
      dispatch({ type: 'UPDATE_PROFILEPICTURE_FAILED' });
    }
  };

const getMyUserProfile = (): AppThunkAction<KnownActions> => async (dispatch) => {
  try {
    const userProfile = await UserService.getUserProfile(await getUserId());
    dispatch({
      type: 'GET_MY_USER_PROFILE_SUCCESS',
      userProfile
    });
  } catch (error) {
    console.log('error', error);
  }
};

const clearUpdateSuccess = (): AppThunkAction<KnownActions> => (dispatch) => {
  dispatch({
    type: 'CLEAR_UPDATE_SUCCESS'
  });
};

export const UserActions = {
  getUserProfile,
  updateUserDescription,
  getUserPosts,
  clearUpdateSuccess,
  updateProfilePicture,
  getMyUserProfile
};

const unloadedState: UserState = {
  userProfile: null,
  isLoading: false,
  updateSuccess: true,
  userPosts: [],
  totalCount: null,
  myUserProfile: null
};

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
  if (state === undefined) return unloadedState;

  const action = incomingAction as KnownActions;
  switch (action.type) {
    case 'GET_USERPROFILE_REQUEST':
      return {
        ...state,
        userProfile: null,
        isLoading: true
      };
    case 'GET_USERPROFILE_SUCCESS':
      return {
        ...state,
        userProfile: action.userProfile,
        isLoading: false
      };

    case 'UPDATE_USERDESCRIPTION_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'UPDATE_USERDESCRIPTION_SUCCESS':
      return {
        ...state,
        isLoading: false,
        updateSuccess: true
      };

    case 'GET_USERPOSTS_REQUEST':
      return {
        ...state,
        userPosts: action.renewal ? [] : state.userPosts,
        isLoading: true
      };
    case 'GET_USERPOSTS_SUCCESS':
      return {
        ...state,
        userPosts: action.userPosts,
        totalCount: action.totalCount,
        isLoading: false
      };

    case 'GET_MY_USER_PROFILE_SUCCESS':
      return { ...state, myUserProfile: action.userProfile };

    case 'CLEAR_UPDATE_SUCCESS':
      return {
        ...state,
        updateSuccess: false
      };
    default:
      return state;
  }
};

interface UpdateUserDescriptionRequest {
  type: 'UPDATE_USERDESCRIPTION_REQUEST';
}
interface UpdateUserDescriptionSuccess {
  type: 'UPDATE_USERDESCRIPTION_SUCCESS';
}
interface UpdateUserDescriptionFailed {
  type: 'UPDATE_USERDESCRIPTION_FAILED';
}

interface GetUserProfileRequest {
  type: 'GET_USERPROFILE_REQUEST';
}
interface GetUserProfileSuccess {
  type: 'GET_USERPROFILE_SUCCESS';
  userProfile: IUserProfile;
}
interface GetUserProfileFailed {
  type: 'GET_USERPROFILE_FAILED';
  error: string;
}

interface GetUserPostsRequest {
  type: 'GET_USERPOSTS_REQUEST';
  renewal: boolean;
}
interface GetUserPostsSuccess {
  type: 'GET_USERPOSTS_SUCCESS';
  userPosts: IUserPost[];
  totalCount: number;
}
interface GetUserPostsFailed {
  type: 'GET_USERPOSTS_FAILED';
}

interface UpdateProfilePictureRequest {
  type: 'UPDATE_PROFILEPICTURE_REQUEST';
}
interface UpdateProfilePictureSuccess {
  type: 'UPDATE_PROFILEPICTURE_SUCCESS';
}
interface UpdateProfilePictureFailed {
  type: 'UPDATE_PROFILEPICTURE_FAILED';
}

interface GetMyUserProfileSuccess {
  type: 'GET_MY_USER_PROFILE_SUCCESS';
  userProfile: IUserProfile;
}

interface ClearUpdateSuccess {
  type: 'CLEAR_UPDATE_SUCCESS';
}

type KnownActions =
  | UpdateUserDescriptionRequest
  | UpdateUserDescriptionSuccess
  | UpdateUserDescriptionFailed
  | GetUserProfileRequest
  | GetUserProfileSuccess
  | GetUserProfileFailed
  | GetUserPostsRequest
  | GetUserPostsSuccess
  | GetUserPostsFailed
  | ClearUpdateSuccess
  | UpdateProfilePictureRequest
  | UpdateProfilePictureSuccess
  | UpdateProfilePictureFailed
  | GetMyUserProfileSuccess;
