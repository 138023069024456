/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Moment from 'moment';
import { useRouter } from 'next/router';
import { PostType } from '../Models/Enums';
import { INotification } from '../Models/Notification';
import userSvg from '../assets/images/user.svg';
import heartSvg from '../assets/images/heart-red.svg';
import speechBubbleSvg from '../assets/images/Speech-bubble.svg';
import PostService from '../Services/Posts/PostService';
import { IForumPostModel } from '../Models/ForumPostModel';

interface Props {
  notification: INotification;
  setOpen: (open: boolean) => void;
}

export default function NotificationCard(props: Props): JSX.Element {
  const { notification, setOpen } = props;
  const router = useRouter();

  const onCardClick = async () => {
    const response = await PostService.getPostById(notification.postId);
    const post = (await response.json()) as IForumPostModel;

    router.push(post.slug, undefined, { shallow: false });
    setOpen(false);
  };

  const renderCard = (): JSX.Element => {
    return (
      <div className="notification-card-container" onClick={() => onCardClick()}>
        <div style={{ position: 'relative' }}>
          {notification.sourceUser.userPictureUrl && !notification.anonymous ? (
            <img src={notification.sourceUser.userPictureUrl} alt="test pic" className="profile-picture" />
          ) : (
            <img alt="UserSvg" src={userSvg} style={{ width: 55, borderRadius: 50 }} />
          )}
          {notification.postType === PostType.LIKE ? (
            <img alt="heartsvg" src={heartSvg} className="notification-card-like-comment-img" />
          ) : (
            <img alt="speechbubble" src={speechBubbleSvg} className="notification-card-like-comment-img" />
          )}
        </div>
        <div style={{ marginLeft: 10 }}>
          {notification.postType === PostType.COMMENT ? (
            <section className="notification-user-name-container">
              {notification.anonymous ? <strong>Anonym</strong> : <strong>{notification.sourceUser.userName}</strong>}
              <span> har kommenterat ett inlägg du följer.</span>
            </section>
          ) : (
            <section className="notification-user-name-container">
              {notification.anonymous ? <strong>Anonym</strong> : <strong>{notification.sourceUser.userName}</strong>}
              <span>
                {' '}
                gillar ditt inlägg: <q style={{ fontStyle: 'italic' }}>{notification.postContent}</q>
              </span>
            </section>
          )}
          <section className="notifications-created-date-container">
            {Moment(notification.createdUtc).format('YYYY-MM-DD')}
          </section>
        </div>
        {!notification.read ? (
          <div style={{ marginLeft: 'auto' }}>
            <span className="notification-unread-message" />
          </div>
        ) : null}
      </div>
    );
  };

  return <>{renderCard()}</>;
}
