/* eslint-disable no-alert */
/* eslint-disable no-console */
import { Account, AuthError, AuthResponse } from 'msal';
import UserService from '../Services/Users/UserService';
import { isClient } from '../utils/Helper';
import { profileEdited, triedToSignIn, idToken as idTokenStorage } from '../utils/LocalStorage';
import { b2cPolicies } from './AuthHelpers';
import { hasValidToken, LOGIN_REQUEST, msalApp } from './AuthUtils';

export async function signInSilently(
  accessTokenSuccess: (account: Account, rawIdToken: string) => void
): Promise<void> {
  if (!isClient()) return;

  try {
    if (msalApp.getAccount() === null) return;

    const { account, idToken } = await msalApp.acquireTokenSilent(LOGIN_REQUEST);
    accessTokenSuccess(account, idToken.rawIdToken);
    localStorage[idTokenStorage] = idToken.rawIdToken;
  } catch (error) {
    window.console.error(error);
  }
}

export default async function signIn(
  accessTokenSuccess: (account: Account, rawIdToken: string) => void
): Promise<void> {
  if (!isClient()) return;

  msalApp.handleRedirectCallback(authRedirectCallBack);

  const msalAccount = msalApp.getAccount();

  // if (localStorage[triedToSignIn]) {
  //   localStorage.removeItem(triedToSignIn);

  //   if (!hasValidToken() && !msalAccount) {
  //     window.location.href = '/';
  //     console.log('has invalid token and no MSAL account (back click)');
  //     return;
  //   }
  // }

  if (hasValidToken() && msalAccount) {
    accessTokenSuccess(msalAccount, msalAccount.accountIdentifier);
    await UserService.upsertUser(msalAccount);
    console.log('has valid token and MSAL account');
    return;
  }

  if (msalAccount) {
    console.log('has MSAL account');
    try {
      const { account, idToken } = await msalApp.acquireTokenSilent(LOGIN_REQUEST);
      accessTokenSuccess(account, idToken.rawIdToken);
      localStorage['msal.idtoken'] = idToken.rawIdToken;

      if (msalAccount) {
        await UserService.upsertUser(msalAccount);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        (error.errorCode && error.errorCode === 'login_required') ||
        (error.name && error.name === 'InteractionRequiredAuthError')
      ) {
        onSignIn();
      }
      if (error.errorCode === 'block_token_requests') {
        // eslint-disable-next-line no-restricted-globals
        if (location.href.indexOf('#') > -1) {
          window.location.href = window.location.origin;
        }
      }
    }
  } else {
    console.log('executing on signin');
    onSignIn();
  }
}

const authRedirectCallBack = async (error: AuthError, response?: AuthResponse): Promise<void> => {
  // Error handling
  if (error) {
    console.log(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf('AADB2C90118') > -1) {
      try {
        // Password reset policy/authority
        msalApp.loginRedirect(b2cPolicies.authorities.forgotPassword);
      } catch (err) {
        console.log(err);
      }
    }
    if (error.errorMessage.indexOf('AADB2C90091') > -1) {
      localStorage[profileEdited] = true;
    }
  } else {
    if (!response) return;

    if (response.tokenType === 'id_token' && response.idToken.claims.tfp === b2cPolicies.names.forgotPassword) {
      msalApp.logout();
      window.alert('Lösenordet har blivit ändrat. \nLogga in med ditt nya lösenord.');
    } else if (response.tokenType === 'id_token' && response.idToken.claims.tfp === b2cPolicies.names.profileEdit) {
      window.alert('Användarnamnet har blivit uppdaterat!');
      localStorage[profileEdited] = true;
      await UserService.updateUserName(response.uniqueId, response.account.name);
    } else if (response.tokenType === 'id_token' && response.idToken.claims.tfp === b2cPolicies.names.signUpSignIn) {
      console.log(`id_token acquired at: ${new Date().toString()}`);
    } else {
      console.log(`Token type is: ${response.tokenType}`);
    }
  }
};

const onSignIn = (): void => {
  if (!isClient()) return;

  try {
    localStorage[triedToSignIn] = 'true';
    msalApp.loginRedirect(LOGIN_REQUEST);
  } catch (error) {
    console.log("oh no, there's an error", error);
  }
};

export const editProfile = (): void => {
  msalApp.handleRedirectCallback(authRedirectCallBack);
  return msalApp.loginRedirect(b2cPolicies.authorities.profileEditing);
};
