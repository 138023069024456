/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import * as Post from './Post/Post';
import * as Comment from './Comment/Comment';
import * as Auth from './Auth/Auth';
import * as User from './User/User';
import * as Like from './Like/Like';
import * as Notification from './Notification/Notification';

export interface ApplicationState {
  post: Post.PostState;
  comment: Comment.CommentState;
  auth: Auth.AuthState;
  user: User.UserState;
  like: Like.LikeState;
  notification: Notification.NotificationState;
}

export const reducers = {
  post: Post.reducer,
  comment: Comment.reducer,
  auth: Auth.reducer,
  user: User.reducer,
  like: Like.reducer,
  notification: Notification.reducer
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState?: () => ApplicationState): void;
}
