import React from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import Header from './Header/Header';
import MenuTabs from './MenuTabs/MenuTabs';
import { MapRoutes, IsTabRoute } from './utils/RouteHelper';
import Footer from './Footer/Footer';
import { hasValidToken } from './Auth/AuthUtils';
import { ApplicationState } from './Store';
import * as AuthStore from './Store/Auth/Auth';
import signIn, { signInSilently } from './Auth/AuthHandler';
import SignalRHandler from './Notifications/SignalRHandler';

interface IExposedProps {
  children: React.ReactNode;
}

type Props = typeof AuthStore.actionCreators & IExposedProps;

const App = (props: Props): JSX.Element => {
  const { accessTokenSuccess, children } = props;

  const router = useRouter();
  const routerPath = router.asPath.split('?')[0];

  const [activeTab, setActiveTab] = React.useState<number>(MapRoutes(undefined, routerPath)?.tab);
  const [tabRoute, setTabRoute] = React.useState<boolean>(IsTabRoute(routerPath));

  React.useEffect(() => {
    if (hasValidToken()) signIn(accessTokenSuccess);
    else signInSilently(accessTokenSuccess);
  }, []);

  React.useEffect(() => {
    setTabRoute(IsTabRoute(routerPath));
    setActiveTab(MapRoutes(undefined, routerPath)?.tab);
    if (router.query) {
      router.replace(routerPath, undefined, { shallow: true });
    }
  }, [router.asPath]);

  const handleRouteChange = async (tab: number): Promise<void> => {
    const _tab = MapRoutes(tab);
    router.push(_tab.route, undefined, { scroll: true, shallow: true });

    setActiveTab(tab);
  };

  return (
    <>
      <SignalRHandler />
      <Header onHeaderClick={(): Promise<void> => handleRouteChange(0)} />
      <div style={{ maxWidth: 1200, margin: '0 auto' }}>
        {tabRoute ? (
          <MenuTabs activeTab={activeTab} setActiveTab={(tab: number): Promise<void> => handleRouteChange(tab)}>
            {children}
          </MenuTabs>
        ) : (
          <section>{children}</section>
        )}
      </div>
      <Footer />
    </>
  );
};

export default connect(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (state: ApplicationState, exposedProps: IExposedProps) => state.auth,
  AuthStore.actionCreators
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(App as any);
