import {
  CategoryIdEnum,
  createNewPostSubCategories,
  recipeSubCategories,
  RecipeSubCategoryIdEnum,
  SubCategoryIdEnum,
  SubCategoryLevel
} from '../Models/Category';
import { ICreatePostRequest } from '../Models/ForumPostModel';
import { IDictionary, ILike } from '../Models/LikeModel';
import { ILikeAction, likeActionStorage } from './LocalStorage';

export const googleAdsenseClientId = 'ca-pub-8999860646525650';
export const googleAdsenseSlotId = '3307343200';

export const getLikeId = (id: string, likes: IDictionary<ILike> | undefined): string | null => {
  let likeId = null;
  if (likes && likes[id]) likeId = likes[id].id;

  return likeId;
};

export const shouldLike = (postId: string, commentId: string): boolean => {
  const likeAction = localStorage[likeActionStorage]
    ? (JSON.parse(localStorage[likeActionStorage]) as ILikeAction)
    : null;
  if (!likeAction) return false;

  if (!likeAction.isComment && likeAction.postId === postId) return true;
  if (likeAction.isComment && likeAction.postId === postId && likeAction.commentId === commentId) return true;

  return false;
};

export const getProfileId = (): string => {
  const pathNames = window.location.pathname.split('/');

  let userId: string;
  if (pathNames.length === 3) {
    userId = pathNames[2];
  } else {
    userId = pathNames[1];
  }

  return userId;
};

export const takePostCount = 10;
export const takeNotificationsCount = 11;
export const takeCommentCount = 10;

export const isValidCreatePostRequest = (post: ICreatePostRequest): boolean => {
  if (!post.title || !post.content) return false;

  return true;
};

export const getImagePreview = (image: File, callback: (imagePreview: string) => void): void => {
  const reader = new FileReader();
  reader.onload = function (e): void {
    const imagePreview = e.target ? (e.target.result as string) : '';

    callback(imagePreview);
  };

  reader.readAsDataURL(image);
};

export const isLocalHost = (): boolean => {
  if (!isClient()) return false;

  return window.location.origin.indexOf('localhost') > -1;
};

export const slugify = (str: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-och-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const isClient = (): boolean => {
  return typeof window !== 'undefined' && window.document !== undefined;
};

export const isGoogleBot = (): boolean => {
  if (!isClient()) return false;

  const botUserAgentsArray = ['googlebot', 'bingbot', 'linkedinbot', 'mediapartners-google', 'lighthouse', 'insights'];
  const userAgent = window.navigator.userAgent.toLowerCase();
  const found = botUserAgentsArray.find((botUserAgent) => {
    return botUserAgent === userAgent;
  });

  return found !== undefined;
};

export const getSubCategorySlug = (
  subCategoryId: string | RecipeSubCategoryIdEnum,
  categoryId: CategoryIdEnum
): string => {
  const subCatKey = Object.keys(SubCategoryIdEnum).find((x) => x === subCategoryId.toString().toUpperCase());

  if (categoryId === CategoryIdEnum.PARENT || categoryId === CategoryIdEnum.PREGNANT) {
    const subLevelId = categoryId === CategoryIdEnum.PARENT ? SubCategoryLevel.PARENT : SubCategoryLevel.PREGNANT;
    return (
      createNewPostSubCategories.find(
        (x) =>
          x.id === SubCategoryIdEnum[subCatKey as keyof typeof SubCategoryIdEnum] &&
          (x.categoryId === SubCategoryLevel.BOTH || x.categoryId === subLevelId)
      )?.slug || ''
    );
  }

  return recipeSubCategories.find((x) => x.id === (subCategoryId as RecipeSubCategoryIdEnum))?.slug || '';
};

export const randomNumberFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
