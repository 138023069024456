import * as React from 'react';
import type { AppProps } from 'next/app';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Head from 'next/head';
import { Router } from 'next/router';
import Script from 'next/script';
import { wrapper } from '../Store/configureStore';
import App from '../App';
import '../Common/common.scss';
import '../index.css';
import '../App.scss';
import '../Footer/Footer.scss';
import '../Header/Header.scss';
import '../MenuTabs/menutabs.scss';
import '../Notifications/notification.scss';
import '../Forum/forum.scss';
import FacebookPixel from '../FacebookPixel/FacebookPixel';
import { useGaTracker } from '../lib/ga/UseGATracker';

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID;

function AppPage({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      document.getElementById('header')?.scrollIntoView({
        behavior: 'smooth'
      });
    });
  }, []);

  React.useEffect(() => {
    useGaTracker().init();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#0C7D89',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#0C7D89'
      }
    },
    typography: {
      allVariants: { fontFamily: 'Poppins' }
    }
  });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=ISO-8859-1" />
        <meta name="facebook-domain-verification" content="oulf49x1ia6zthuj676slji7ilc2ke" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta property="fb:app_id" content="&#123;539696253420825&#125;" />
      </Head>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </Script>
      <FacebookPixel />
      <ThemeProvider theme={theme}>
        <App>
          <Component {...pageProps} />
        </App>
      </ThemeProvider>
    </>
  );
}

export default wrapper.withRedux(AppPage);
