import { Api } from '../Api';
import {
  ICreatePostRequest,
  IDeletePostRequest,
  IPagedPostResponse,
  ISearchPostRequest,
  IUpdatePostRequest
} from '../../Models/ForumPostModel';
import { IPagedPostRequest } from '../../Models/RequestModel';
import { getUserId } from '../../Auth/AuthUtils';
import UserService from '../Users/UserService';

export default class PostService {
  static async getPostById(postId: string): Promise<Response> {
    const api = Api(false);
    return api.get(`Post/${postId}`);
  }

  static async getPostsByCategoryId(getPostRequest: IPagedPostRequest): Promise<Response> {
    const api = Api(false);

    return api.post(getPostRequest, `Posts`);
  }

  static async createPost(post: ICreatePostRequest): Promise<Response> {
    const api = Api(true);

    const userId = await getUserId();
    const userProfile = await UserService.getUserProfile(userId);
    post.userPictureUrl = userProfile.userPictureUrl;

    return api.post(post, 'api/Post');
  }

  static async updatePost(post: IUpdatePostRequest, postId: string): Promise<Response> {
    const api = Api(true);
    return api.put(post, `api/Post/${postId}`);
  }

  static async deletePost(post: IDeletePostRequest): Promise<Response> {
    const api = Api(true);
    return api.delete(post, 'api/Post');
  }

  static async searchPost(searchPostRequest: ISearchPostRequest): Promise<IPagedPostResponse> {
    const api = Api(false);
    const results = await api.post(searchPostRequest, 'Posts/Search');
    return results.json();
  }

  static async getMyPostIds(postId: string): Promise<string[]> {
    const api = Api(true);
    const results = await api.get(`api/Post/MyPosts/${postId}`);
    return results.json();
  }
}
