import { Account } from 'msal';
import {
  IUserProfile,
  IUpdateUserDecsriptionRequest,
  IUpdateUserNameRequest,
  IGetUserPostRequest,
  IGetUserResponse,
  IUpdateProfilePictureRequest
} from '../../Models/User';
import { Api } from '../Api';

export default class UserService {
  static async upsertUser(account: Account): Promise<Response> {
    const api = Api(true);
    return api.post({ userId: account.accountIdentifier, userName: account.name }, 'api/User');
  }

  static async updateUserDescription(userDescriptionRequest: IUpdateUserDecsriptionRequest): Promise<Response> {
    const api = Api(true);
    return api.put(userDescriptionRequest, 'api/User/UpdateDescription');
  }

  static async getUserProfile(userId: string): Promise<IUserProfile> {
    const api = Api(true);
    const response = await api.get(`api/UserProfile/${userId}`);
    return (await response.json()) as IUserProfile;
  }

  static async getUserPosts(req: IGetUserPostRequest): Promise<IGetUserResponse> {
    const api = Api(true);
    const response = await api.post(req, `api/UserPosts`);
    return (await response.json()) as IGetUserResponse;
  }

  static async updateUserName(userId: string, userName: string): Promise<void> {
    const api = Api(true);
    await api.put({ userId, userName } as IUpdateUserNameRequest, `api/User/UpdateUserName`);
  }

  static async updateProfilePicture(imageUrl: string): Promise<void> {
    const api = Api(true);
    await api.put({ imageUrl, userId: '' } as IUpdateProfilePictureRequest, 'api/User/UpdateProfilePicture');
  }
}
