import { UserAgentApplication, Configuration, Logger, LogLevel, AuthenticationParameters } from 'msal';
import { isClient, isLocalHost } from '../utils/Helper';
import { idToken as idTokenStorage } from '../utils/LocalStorage';
import { b2cPolicies } from './AuthHelpers';

const LOGIN_SCOPES = {
  clientId: '14728658-baf8-4d4e-9dcb-dd66301ff318'
};

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read'
};

export const GRAPH_REQUEST = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ]
  }
};

export const LOGIN_REQUEST_FORCE_REFRESH = {
  scopes: [LOGIN_SCOPES.clientId],
  forceRefresh: true
} as AuthenticationParameters;

export const LOGIN_REQUEST = {
  scopes: [LOGIN_SCOPES.clientId]
  // forceRefresh: true
} as AuthenticationParameters;

const loggerCallback = (logLevel: LogLevel, message: string): void => {
  // eslint-disable-next-line no-console
  console.log(`Auth: [${logLevel} ${message}`);
};
const logger = new Logger(loggerCallback, {
  correlationId: '1234',
  level: isLocalHost() ? LogLevel.Info : LogLevel.Error,
  piiLoggingEnabled: !!isLocalHost()
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const msalApp: UserAgentApplication | any = isClient()
  ? new UserAgentApplication({
      auth: {
        clientId: LOGIN_SCOPES.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        validateAuthority: true,
        knownAuthorities: [b2cPolicies.authorities.root],
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      },
      system: {
        logger
      }
    } as Configuration)
  : null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseJwt(token: string): any {
  if (!isClient()) return '';

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const getUserId = async (): Promise<string> => {
  const idToken = await getIdToken();
  if (!idToken) return '';
  const parsedToken = parseJwt(idToken);
  return parsedToken.oid as string;
};

export const getUserName = async (): Promise<string> => {
  const idToken = await getIdToken();
  const parsedToken = parseJwt(idToken);
  return parsedToken.name;
};

export const hasValidToken = (): boolean => {
  return validJWTToken();
};

export const getIdToken = async (): Promise<string> => {
  if (!isClient()) return '';

  const validToken = validJWTToken();
  const existingToken = localStorage[idTokenStorage];

  if (validToken) {
    return existingToken;
  }

  if (msalApp.getAccount() != null) {
    try {
      await msalApp.acquireTokenSilent(LOGIN_REQUEST);
    } catch (error) {
      localStorage[idTokenStorage] = '';
    }
  } else {
    localStorage[idTokenStorage] = '';
  }

  return localStorage[idTokenStorage];
};

export const validJWTToken = (): boolean => {
  if (!isClient()) return false;

  const existingToken = localStorage[idTokenStorage];
  if (!existingToken) return false;
  const jwtToken = parseJwt(existingToken);

  if (Date.now() >= jwtToken.exp * 1000) {
    localStorage[idTokenStorage] = '';
    return false;
  }
  return true;
};

export const logOut = (): void => {
  if (!isClient()) return;

  msalApp.logout();
  localStorage[idTokenStorage] = '';
};
