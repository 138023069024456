/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIdToken } from '../Auth/AuthUtils';
import { isLocalHost } from '../utils/Helper';

const baseUrl = 'https://babyforum-apim.azure-api.net/';
// const baseUrl = 'http://127.0.0.1:7071/api/';

interface IApi {
  get: (url: string) => Promise<Response>;
  post: (obj: unknown, url: string) => Promise<Response>;
  put: (obj: unknown, url: string) => Promise<Response>;
  delete: (obj: unknown, url: string) => Promise<Response>;
  postFormData(obj: unknown, url: string): Promise<Response>;
}

export const Api = (privateEndpoint: boolean): IApi => {
  const getRequestObject = async (method: string): Promise<RequestInit> => {
    const requestObject = {
      method
    } as RequestInit;

    if (privateEndpoint) {
      requestObject.headers = new Headers({ Authorization: `Bearer ${await getIdToken()}` });
    }

    return requestObject;
  };

  const getUrl = (url: string): string => {
    if (isLocalHost() && baseUrl.indexOf('localhost') > -1) {
      return baseUrl + url.replace('api', '');
    }
    return baseUrl + url;
  };

  return {
    async get(url: string): Promise<Response> {
      return fetch(getUrl(url), await getRequestObject('GET'));
    },
    async post(obj: unknown, url: string): Promise<Response> {
      const reqObj = await getRequestObject('POST');
      reqObj.body = JSON.stringify(obj);

      const response = await fetch(getUrl(url), reqObj);
      return response;
    },
    async postFormData(obj: any, url: string): Promise<Response> {
      const reqObj = await getRequestObject('POST');
      reqObj.body = obj;

      return fetch(getUrl(url), reqObj);
    },
    async put(obj: unknown, url: string): Promise<Response> {
      const reqObj = await getRequestObject('PUT');
      reqObj.body = JSON.stringify(obj);

      return fetch(getUrl(url), reqObj);
    },
    async delete(obj: unknown, url: string): Promise<Response> {
      const reqObj = await getRequestObject('DELETE');
      if (obj) reqObj.body = JSON.stringify(obj);

      return fetch(getUrl(url), reqObj);
    }
  };
};
