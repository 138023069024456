import * as React from 'react';
import { IconButton, SwipeableDrawer } from '@mui/material';
import { Close } from '@mui/icons-material';
import NotificationCard from './NotificationCard';
import { INotification } from '../Models/Notification';
import { useIntersectionObserver } from '../Common/UseIntersectionObserver';

interface Props {
  open: boolean;
  notifications: INotification[];
  setOpen: (open: boolean) => void;
  toggleDrawer: (open: boolean) => void;
  loadMore: () => void;
}

export default function NotificationsDrawer(props: Props): JSX.Element {
  const { open, setOpen, toggleDrawer, notifications, loadMore } = props;
  const ref = React.useRef(null);

  const isBottomVisible = useIntersectionObserver(
    ref,
    {
      threshold: 0 // trigger event as soon as the element is in the viewport.
    },
    false // don't remove the observer after intersected.
  );

  React.useEffect(() => {
    if (isBottomVisible) {
      loadMore();
    }
  }, [isBottomVisible]);

  return (
    <SwipeableDrawer
      style={{ width: 370 }}
      anchor="right"
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <div className="notifications-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Aviseringar</h2>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            style={{ marginLeft: 'auto', height: 50 }}
          >
            <Close />
          </IconButton>
        </div>
        {notifications.map((v, index) => {
          return <NotificationCard notification={v} key={index} setOpen={setOpen} />;
        })}
        <div ref={ref} style={{ width: '100%', height: '20px' }} />
      </div>
    </SwipeableDrawer>
  );
}
