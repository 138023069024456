import { Api } from '../Api';
import {
  ICommentModel,
  ICommentsPagedRequest,
  ICreateCommentRequest,
  IDeleteCommentRequest,
  IUpdateCommentRequest
} from '../../Models/CommentModel';
import { getUserId, getUserName } from '../../Auth/AuthUtils';
import UserService from '../Users/UserService';

export default class CommentService {
  static async getCommentById(commentId: string): Promise<ICommentModel> {
    const api = Api(true);
    const response = await api.get(`api/Comment/${commentId}`);
    return (await response.json()) as ICommentModel;
  }

  static async getCommentsByPostIdPaged(commentsRequest: ICommentsPagedRequest): Promise<Response> {
    const api = Api(false);
    return api.post(commentsRequest, 'CommentsPaged');
  }

  static async getCommentsByPostId(postId: string): Promise<Response> {
    const api = Api(true);
    return api.get(`api/Comment?postId=${postId}`);
  }

  static async createComment(comment: ICreateCommentRequest): Promise<Response> {
    const api = Api(true);

    const userId = await getUserId();
    const userProfile = await UserService.getUserProfile(userId);

    comment.userId = userId;
    comment.userUserName = await getUserName();
    comment.userPictureUrl = userProfile.userPictureUrl;

    return api.post(comment, 'api/Comment');
  }

  static async deleteComment(commentDeleteRequest: IDeleteCommentRequest): Promise<Response> {
    const api = Api(true);
    return api.delete(commentDeleteRequest, 'api/Comment');
  }

  static async updateComment(editCommentRequest: IUpdateCommentRequest, commentId: string): Promise<Response> {
    const api = Api(true);
    return api.put(editCommentRequest, `api/Comment/${commentId}`);
  }
}
