import * as React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { connect, ConnectedProps } from 'react-redux';
import { hasValidToken } from '../Auth/AuthUtils';
import { IGetNotificationsByUserIdRequest } from '../Models/Notification';
import { IPagedRequest } from '../Models/RequestModel';
import { ApplicationState } from '../Store';
import { NotificationActions } from '../Store/Notification/Notification';
import { takeNotificationsCount } from '../utils/Helper';
import NotificationsDrawer from './NotificationsDrawer';

type ReduxProps = ConnectedProps<typeof connector>;

const defaultPageRequest = { skip: 0, take: takeNotificationsCount } as IPagedRequest;

const NotificationsMain = (props: ReduxProps): JSX.Element | null => {
  const {
    getNotificationsByUserId,
    notifications,
    getUnreadNotificationsCount,
    unreadNotifications,
    totalCount,
    setReadNotifications
  } = props;

  const [pageRequest, setPageRequest] = React.useState<IPagedRequest>(defaultPageRequest);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [triggerAnimation, setTriggerAnimation] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (hasValidToken()) getUnreadNotificationsCount();

    setIsTokenValid(hasValidToken());
  }, []);

  React.useEffect(() => {
    if (unreadNotifications) {
      setTriggerAnimation(true);

      setTimeout(() => {
        setTriggerAnimation(false);
      }, 3000);
    }
  }, [unreadNotifications]);

  const toggleDrawer = (_open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (_open) {
      getNotificationsByUserId(defaultPageRequest as IGetNotificationsByUserIdRequest, true);
      setPageNumber(1);
      setPageRequest(defaultPageRequest);
    }

    setOpen(_open);

    if (unreadNotifications) setReadNotifications();
  };

  const showMoreClicked = () => {
    if (totalCount && totalCount > pageNumber * takeNotificationsCount) {
      const newPageReq = { ...pageRequest };
      newPageReq.skip += takeNotificationsCount;
      setPageRequest(newPageReq);
      setPageNumber(pageNumber + 1);

      getNotificationsByUserId(newPageReq, false);
    }
  };

  return isTokenValid ? (
    <div className="notifications-icon--container">
      <div style={{ cursor: 'pointer' }} onClick={toggleDrawer(true)}>
        {!unreadNotifications ? (
          <NotificationsNoneIcon className="bell bell--inactive" />
        ) : triggerAnimation ? (
          <NotificationsIcon className="swing animated bell bell--active" />
        ) : (
          <NotificationsIcon className="bell bell--active" />
        )}
      </div>
      <NotificationsDrawer
        loadMore={showMoreClicked}
        notifications={notifications}
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
      />
    </div>
  ) : null;
};

const connector = connect((state: ApplicationState) => {
  return { ...state.notification, ...state.auth };
}, NotificationActions);

export default connector(NotificationsMain);
