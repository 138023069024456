export const idToken = 'msal.idtoken';
export const redirectToStorage = 'redirectTo';
export const profileEdited = 'profileEdited';
export const likeActionStorage = 'doLike';
export const createPostStorage = 'createPost';
export const triedToSignIn = 'triedToSignIn';

/** * LOCAL STORAGE VALUES ** */
export const redirectToProfile = 'REDIRECT_TO_PROFILE';

export interface ILikeAction {
  isComment: boolean;
  postId: string;
  commentId?: string;
}
