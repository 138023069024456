/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Account } from 'msal';
import { Reducer, Action } from 'redux';
import { redirectToStorage } from '../../utils/LocalStorage';
// eslint-disable-next-line import/no-cycle
import { UserActions } from '../User/User';
// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from '../index';

export interface AuthState {
  account: Account | null;
  idToken: string | null;
  error: string | null;
  redirectTo: string | null;
}

export const actionCreators = {
  accessTokenSuccess:
    (account: Account, rawIdToken: string): AppThunkAction<KnownAction | any> =>
    (dispatch) => {
      dispatch({
        type: 'ACCESS_TOKEN_SUCCESS',
        account,
        idToken: rawIdToken
      });

      UserActions.getMyUserProfile()(dispatch);
    },
  accessTokenFailed:
    (error: string): AppThunkAction<KnownAction> =>
    (dispatch) => {
      dispatch({
        type: 'ACCESS_TOKEN_FAILED',
        error
      });
    },
  signOut: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({
      type: 'SIGN_OUT_REQUEST'
    });
  },
  setRedirectTo:
    (redirectTo: string | null): AppThunkAction<KnownAction> =>
    () => {
      localStorage[redirectToStorage] = redirectTo;
    }
};

const unloadedState: AuthState = { account: null, idToken: null, error: null, redirectTo: null };

export const reducer: Reducer<AuthState> = (state: AuthState | undefined, incomingAction: Action): AuthState => {
  if (state === undefined) return unloadedState;

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'ACCESS_TOKEN_SUCCESS':
      return {
        ...state,
        account: action.account,
        idToken: action.idToken,
        error: null
      };
    case 'ACCESS_TOKEN_FAILED':
      return {
        ...state,
        account: null,
        idToken: null,
        error: action.error
      };
    case 'SIGN_OUT_REQUEST':
      return {
        ...state,
        account: null,
        idToken: null,
        error: null
      };
    case 'SET_REDIRECT_TO':
      return {
        ...state,
        redirectTo: action.redirectTo
      };
    default:
      return state;
  }
};

interface AccessTokenSuccess {
  type: 'ACCESS_TOKEN_SUCCESS';
  account: Account | null;
  idToken: string | null;
}
interface AccessTokenFailed {
  type: 'ACCESS_TOKEN_FAILED';
  error: string | null;
}
interface SignOutRequest {
  type: 'SIGN_OUT_REQUEST';
}
interface SetRedirectTo {
  type: 'SET_REDIRECT_TO';
  redirectTo: string | null;
}

type KnownAction = AccessTokenSuccess | AccessTokenFailed | SignOutRequest | SetRedirectTo;
