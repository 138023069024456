/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isMobile } from 'react-device-detect';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import { Avatar } from '@mui/material';
import { ApplicationState } from '../Store';
import { hasValidToken } from '../Auth/AuthUtils';
import NotificationsMain from '../Notifications/NotificationsMain';
import { getCdnUrl } from '../utils/env';
import userSvg from '../assets/images/user.svg';
import { isGoogleBot } from '../utils/Helper';

type ReduxProps = ConnectedProps<typeof connector>;

interface IExposedProps {
  onHeaderClick: () => void;
}
type Props = IExposedProps & ReduxProps;

const Header = (props: Props): JSX.Element => {
  const router = useRouter();
  const { user, onHeaderClick } = props;

  const [tokenIsValid, setTokenIsValid] = React.useState<boolean>(false);
  const [isClient, setIsClient] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTokenIsValid(hasValidToken());
    setIsClient(true);
  }, []);

  const onRegisterClick = () => {
    router.push('/signin');
  };
  const onProfileClick = () => {
    router.push('/minprofil');
  };

  return (
    <>
      <header className="header" id="header">
        <div className="header_logo" onClick={() => onHeaderClick()} />
        {/* {!isMobile ? (
          <img
            onClick={(): void => onHeaderClick()}
            className="header_logo"
            src={getCdnUrl('/images/babyforum-ikon.png')}
            alt="Baby Forum"
          />
        ) : (
          <img
            onClick={(): void => onHeaderClick()}
            className="header_logo"
            src={getCdnUrl('/images/babyforum_logo.png')}
            alt="Baby Forum"
          />
        )} */}

        <div className="header-link-container">
          <a className="header-link" href="mailto:marknadsfora-babyforum@outlook.com">
            Annonsering
          </a>

          {!tokenIsValid ? (
            <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => onRegisterClick()}>
              <div className="header-link">Bli medlem</div>
              {isClient && !isMobile ? <div className="link_container">Logga in</div> : null}
            </div>
          ) : null}
        </div>
        {tokenIsValid ? (
          <div style={{ display: 'flex', paddingRight: 10 }}>
            <NotificationsMain />
            <div
              style={{ marginLeft: 15 }}
              onClick={() => {
                onProfileClick();
              }}
            >
              {user.myUserProfile?.userPictureUrl ? (
                <img
                  src={user.myUserProfile.userPictureUrl}
                  alt="pic url"
                  style={{ width: 'auto', height: 48 }}
                  className="profile-picture"
                />
              ) : (
                <Avatar style={{ width: 45, height: 45 }}>
                  <img alt="UserSvg" src={userSvg} style={{ height: 25 }} />
                </Avatar>
              )}
            </div>
          </div>
        ) : null}

        {!tokenIsValid && isClient && isMobile && (
          <div className="link_container" onClick={() => onRegisterClick()}>
            Logga in
          </div>
        )}
      </header>
      {!isGoogleBot() && (
        <CookieConsent
          location="none"
          buttonText="Jag förstår!"
          buttonStyle={{ backgroundColor: '#0C7D89', borderRadius: 10, padding: 10, color: 'white' }}
          style={{ position: 'unset' }}
        >
          Denna webbplats använder cookies för att förbättra användarupplevelsen.{'   '}
          <a style={{ textDecoration: 'underline' }} href="/cookies">
            Läs mer här!
          </a>
        </CookieConsent>
      )}
    </>
  );
};

const connector = connect((state: ApplicationState, ownProps: IExposedProps) => {
  return { auth: state.auth, user: state.user };
});
export default connector(Header);
