/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';

export default function Footer(): JSX.Element {
  return (
    <footer className="footer-container">
      <div className="footer-first-section">
        <a href="/signin">
          <h3 aria-label="Bli medlem">Bli medlem</h3>
        </a>
      </div>
      <div className="footer-second-section">
        <a href="mailto:kontakt-babyforum@outlook.com?subject=Kontakt">
          <h3 aria-label="Kontakt">Kontakt</h3>
        </a>
        <a href="mailto:kontakt-babyforum@outlook.com?subject=Annonsering">
          <h3 aria-label="Annonsering">Annonsering</h3>
        </a>
      </div>
      <div className="footer-third-section">
        <a href="/integritetspolicy">
          <h3 aria-label="GDPR och Integritetspolicy">GDPR och Integritetspolicy</h3>
        </a>
      </div>
    </footer>
  );
}
