import * as SignalR from '@microsoft/signalr';
import { getIdToken, getUserId } from '../../Auth/AuthUtils';
import { IGetNotificationsByUserIdRequest, IPagedNotificationResponse } from '../../Models/Notification';
import { Api } from '../Api';

const baseUrl = 'https://babyforum-apim.azure-api.net/';
// const baseUrl = 'http://localhost:7071/';

export default class NotificationService {
  static async setUpSignalR(): Promise<SignalR.HubConnection> {
    try {
      const response = await fetch(`${baseUrl}api/negotiate`, {
        method: 'post',
        headers: {
          'user-id': await getUserId(),
          Authorization: `Bearer ${await getIdToken()}`
        }
      } as RequestInit);

      if (!response.ok) {
        return Promise.reject(new Error('Error while setting up SignalR'));
      }

      const results = await response.json();

      const accessToken = results.accessToken;
      const url = results.url;

      const options = {
        accessTokenFactory: () => accessToken
      };

      return new SignalR.HubConnectionBuilder()
        .withUrl(url, options)
        .configureLogging(SignalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getNotifications(
    getNotificationsRequest: IGetNotificationsByUserIdRequest
  ): Promise<IPagedNotificationResponse> {
    const api = Api(true);
    const response = await api.post(getNotificationsRequest, 'api/getnotificationsbyuserid');
    return (await response.json()) as IPagedNotificationResponse;
  }

  static async getUnreadNotificationsCount(): Promise<number> {
    const api = Api(true);

    const response = await api.get('api/getunreadnotificationscount');
    return (await response.json()) as number;
  }

  static async setReadNotifications(): Promise<void> {
    const api = Api(true);

    await api.post({}, 'api/setreadnotifications');
  }
}
