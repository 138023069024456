/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { combineReducers, Reducer, AnyAction } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { ApplicationState, reducers } from './index';

const combinedReducer = combineReducers({
  ...reducers
});

const reducer: Reducer<ApplicationState, AnyAction> = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    };

    return nextState;
  }
  return combinedReducer(state, action);
};

export const initStore = () => {
  return configureStore({
    reducer
  });
};

export const wrapper = createWrapper(initStore);
