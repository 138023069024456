import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as SignalR from '@microsoft/signalr';
import { ApplicationState } from '../Store';
import { actionCreators as AuthActions } from '../Store/Auth/Auth';
import NotificationService from '../Services/Notifications/NotificationService';
import { NotificationActions } from '../Store/Notification/Notification';

type ReduxProps = ConnectedProps<typeof connector>;

const SignalRHandler = (props: ReduxProps): JSX.Element => {
  const { account, getUnreadNotificationsCount } = props;
  const [hubConnection, setHubConnection] = React.useState<SignalR.HubConnection | null>(null);

  React.useEffect(() => {
    if (!account || hubConnection) return;

    async function setUpSignalR() {
      const conn = await NotificationService.setUpSignalR();

      setHubConnection(conn);
    }

    setUpSignalR();
  }, [account]);

  React.useEffect(() => {
    if (!hubConnection) return;

    // eslint-disable-next-line no-console
    hubConnection.start().catch(console.error);

    hubConnection.on('newNotification', () => {
      getUnreadNotificationsCount();
    });
  }, [hubConnection]);

  return <div />;
};

const connector = connect((state: ApplicationState) => state.auth, { ...AuthActions, ...NotificationActions });

export default connector(SignalRHandler);
