/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { IGetNotificationsByUserIdRequest, INotification } from '../../Models/Notification';
import NotificationService from '../../Services/Notifications/NotificationService';
import { getIdToken } from '../../Auth/AuthUtils';

export interface NotificationState {
  notifications: INotification[];
  totalCount: number;
  unreadNotifications: number;
}

const getNotificationsByUserId =
  (notificationsRequest: IGetNotificationsByUserIdRequest, renewal: boolean): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    try {
      const token = await getIdToken();

      if (!token) {
        dispatch({
          type: 'GET_NOTIFICATIONS_BY_USER_ID_SUCCESS',
          notifications: [],
          totalCount: 0
        });
        return;
      }

      const notificationResponse = await NotificationService.getNotifications(notificationsRequest);

      if (!renewal) {
        notificationResponse.notifications = getState
          ? getState().notification.notifications.concat(notificationResponse.notifications)
          : notificationResponse.notifications;
      }

      dispatch({
        type: 'GET_NOTIFICATIONS_BY_USER_ID_SUCCESS',
        notifications: notificationResponse.notifications,
        totalCount: notificationResponse.totalCount
      });
    } catch (error) {
      console.log(error);
    }
  };

const getUnreadNotificationsCount = (): AppThunkAction<KnownActions> => async (dispatch) => {
  try {
    const token = await getIdToken();

    if (!token) {
      dispatch({
        type: 'GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS',
        unreadNotifications: 0
      });
    }

    const unreadNotifications = await NotificationService.getUnreadNotificationsCount();

    dispatch({
      type: 'GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS',
      unreadNotifications
    });
  } catch (error) {
    console.log(error);
  }
};

const setReadNotifications = (): AppThunkAction<KnownActions> => async (dispatch) => {
  try {
    await NotificationService.setReadNotifications();

    getUnreadNotificationsCount()(dispatch);
  } catch (error) {
    console.log(error);
  }
};

export const NotificationActions = {
  getNotificationsByUserId,
  getUnreadNotificationsCount,
  setReadNotifications
};

const unloadedState: NotificationState = { notifications: [], unreadNotifications: 0, totalCount: 0 };

export const reducer: Reducer<NotificationState> = (
  state: NotificationState | undefined,
  incomingAction: Action
): NotificationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownActions;

  switch (action.type) {
    case 'GET_NOTIFICATIONS_BY_USER_ID_SUCCESS':
      return {
        notifications: action.notifications,
        totalCount: action.totalCount,
        unreadNotifications: state.unreadNotifications
      };

    case 'GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS':
      return {
        notifications: state.notifications,
        totalCount: state.totalCount,
        unreadNotifications: action.unreadNotifications
      };

    default:
      return state;
  }
};

interface GetNotificationsByUserIdSuccess {
  type: 'GET_NOTIFICATIONS_BY_USER_ID_SUCCESS';
  notifications: INotification[];
  totalCount: number;
}

interface GetUnreadNotificationsCountSuccess {
  type: 'GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS';
  unreadNotifications: number;
}

type KnownActions = GetNotificationsByUserIdSuccess | GetUnreadNotificationsCountSuccess;
