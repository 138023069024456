/* eslint-disable consistent-return */
/* eslint-disable no-multi-assign */
import * as React from 'react';

export function useIntersectionObserver(
  ref: React.MutableRefObject<Element | null>,
  options: IntersectionObserverInit = {},
  forward = true
): boolean {
  const [element, setElement] = React.useState<Element | null>(null);
  const [isIntersecting, setIsIntersecting] = React.useState(false);
  const observer = React.useRef<null | IntersectionObserver>(null);

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  React.useEffect(() => {
    setElement(ref.current);
  }, [ref.current]);

  React.useEffect(() => {
    if (!element) return;

    cleanOb();

    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!forward) {
          setIsIntersecting(isElementIntersecting);
        } else if (forward && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          cleanOb();
        }
      },
      { ...options }
    ));
    ob.observe(element);

    return function cleanup() {
      cleanOb();
    };
  }, [element, options]);

  return isIntersecting;
}
