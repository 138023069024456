import { ISearchPostRequest } from '../../Models/ForumPostModel';
import { IPagedRecipeResponse } from '../../Models/RecipeModel';
import { IPagedRequest } from '../../Models/RequestModel';
import { Api } from '../Api';

export default class RecipeService {
  static async getRecipes(getRecipesPaged: IPagedRequest): Promise<Response> {
    const api = Api(false);
    return api.post(getRecipesPaged, `recipes`);
  }

  static async getRecipesByCategoryId(getRecipesPaged: IPagedRequest): Promise<Response> {
    const api = Api(false);
    return api.post(getRecipesPaged, `recipes/category/{categoryId}`);
  }

  static async getRecipeById(id: string): Promise<Response> {
    const api = Api(false);
    return api.get(`recipes/${id}`);
  }

  static async searchRecipes(searchPostRequest: ISearchPostRequest): Promise<IPagedRecipeResponse> {
    const api = Api(false);
    const results = await api.post(searchPostRequest, 'recipes/search');
    return results.json();
  }
}
