import ReactGA from 'react-ga4';

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID || '';

export const useGaTracker = () => {
  const init = (): void => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(GA_MEASUREMENT_ID);
    }
  };
  // useEffect(() => {
  //   if (initialized && !window.location.href.includes('localhost')) {
  //     ReactGA.send({ hitType: 'pageview', page: router.asPath });
  //   }
  // }, [initialized, router]);

  const trackEvent = (eventName: string, category: string, label: string, value: number): void => {
    ReactGA.event({ action: eventName, category, label, value });
  };

  return { trackEvent, init };
};
