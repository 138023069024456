import * as React from 'react';
import { useRouter } from 'next/router';

export default function FacebookPixel() {
  const router = useRouter();

  React.useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('511199730522362');
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  });

  return null;
}
