/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Action, Reducer } from 'redux';
import {
  IForumPostModel,
  ICreatePostRequest,
  IPagedPostResponse,
  ISearchPostRequest
} from '../../Models/ForumPostModel';
// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from '../index';
import { CategoryIdEnum, SubCategoryIdEnum } from '../../Models/Category';
import PostService from '../../Services/Posts/PostService';
import { getUserId, getUserName } from '../../Auth/AuthUtils';
import { IPagedPostRequest } from '../../Models/RequestModel';
import { takePostCount } from '../../utils/Helper';
import { RecipeModel } from '../../Models/RecipeModel';
import RecipeService from '../../Services/Recipe/RecipeService';

export interface PostState {
  pregnant: IForumPostModel[];
  pregnantTotalCount: number | null;
  isLoadingPregnant: boolean;
  parent: IForumPostModel[];
  parentTotalCount: number | null;
  isLoadingParent: boolean;
  topPosts: IForumPostModel[];
  isLoadingTopPosts: boolean;
  threadPost: IForumPostModel | RecipeModel | null;
  searchResults: IPagedPostResponse;
  requestDone: boolean;
}

const setCurrentThreadPost =
  (threadPost: IForumPostModel | RecipeModel | null): AppThunkAction<KnownActions> =>
  (dispatch) => {
    dispatch({
      type: 'SET_CURRENT_THREAD_POST',
      threadPost
    });
  };

const getPostById =
  (postId: string, categoryId?: CategoryIdEnum): AppThunkAction<KnownActions> =>
  async (dispatch) => {
    dispatch({
      type: 'GET_POST_BY_ID_REQUEST'
    });

    try {
      const response =
        categoryId === CategoryIdEnum.RECIPE
          ? await RecipeService.getRecipeById(postId)
          : await PostService.getPostById(postId);
      const data = (await response.json()) as IForumPostModel;

      dispatch({
        type: 'GET_POST_BY_ID_SUCCESS',
        threadPost: data
      });
    } catch (error) {
      dispatch({
        type: 'GET_POST_BY_ID_FAILED'
      });
    }
  };

const getPostsByCategoryId =
  (getPostRequest: IPagedPostRequest, renewal: boolean): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    const { categoryId } = getPostRequest;
    const isPregnantRequest = categoryId === CategoryIdEnum.PREGNANT;
    const isParent = categoryId === CategoryIdEnum.PARENT;

    dispatch({
      type: isPregnantRequest ? 'GET_PREGNANT_REQUEST' : isParent ? 'GET_PARENT_REQUEST' : 'GET_TOPPOST_REQUEST'
    });

    try {
      const response = await PostService.getPostsByCategoryId(getPostRequest);
      const data = (await response.json()) as IPagedPostResponse;

      let existingData: IForumPostModel[] = [];

      if (!renewal) {
        if (getState)
          existingData = isParent ? getState().post.parent : isPregnantRequest ? getState().post.pregnant : [];

        data.posts = existingData.concat(data.posts);
      }

      dispatch({
        type: isPregnantRequest ? 'GET_PREGNANT_SUCCESS' : isParent ? 'GET_PARENT_SUCCESS' : 'GET_TOPPOST_SUCCESS',
        forumPosts: data.posts,
        totalCount: data.totalCount
      });
    } catch (error) {
      dispatch({
        type: isPregnantRequest ? 'GET_PREGNANT_FAILED' : isParent ? 'GET_PARENT_FAILED' : 'GET_TOPPOST_FAILED'
      });
    }
  };

const createPost =
  (post: ICreatePostRequest): AppThunkAction<KnownActions> =>
  async (dispatch) => {
    dispatch({
      type: 'CREATE_POST_REQUEST'
    });

    try {
      post.userId = await getUserId();
      post.userUserName = await getUserName();

      if (post.subCategoryId === SubCategoryIdEnum.ALL || !post.subCategoryId) {
        post.subCategoryId = SubCategoryIdEnum.OTHER;
      }

      await PostService.createPost(post);
      dispatch({
        type: 'CREATE_POST_SUCCESS'
      });

      getPostsByCategoryId(
        { categoryId: post.categoryId, skip: 0, take: takePostCount } as IPagedPostRequest,
        true
      )(dispatch);
    } catch (error) {
      dispatch({
        type: 'CREATE_POST_FAILED'
      });
    }
  };

const getPostBySearchPhrase =
  (searchPostRequest: ISearchPostRequest, renewal: boolean): AppThunkAction<KnownActions> =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: 'SEARCH_REQUEST' });

      const data = await PostService.searchPost(searchPostRequest);

      let existingData: IForumPostModel[] = [];

      if (!renewal) {
        if (getState) existingData = getState().post.pregnant;

        data.posts = existingData.concat(data.posts);
      }

      dispatch({
        type: 'SEARCH_SUCCESS',
        searchResults: data
      });
    } catch (error) {
      dispatch({
        type: 'SEARCH_FAILED'
      });
    }
  };

export const PostActions = {
  getPostsByCategoryId,
  createPost,
  setCurrentThreadPost,
  getPostById,
  getPostBySearchPhrase
};

const unloadedState: PostState = {
  pregnant: [],
  isLoadingPregnant: false,
  parent: [],
  isLoadingParent: false,
  topPosts: [],
  isLoadingTopPosts: false,
  threadPost: null,
  parentTotalCount: null,
  pregnantTotalCount: null,
  searchResults: { posts: [], totalCount: 0 },
  requestDone: false
};

export const reducer: Reducer<PostState> = (state: PostState | undefined, incomingAction: Action): PostState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownActions;
  switch (action.type) {
    case 'SEARCH_REQUEST':
      return { ...state, requestDone: false };
    case 'SEARCH_SUCCESS':
      return { ...state, searchResults: action.searchResults, requestDone: true };
    case 'SEARCH_FAILED':
      return { ...state, searchResults: unloadedState.searchResults, requestDone: false };

    case 'GET_PREGNANT_REQUEST':
      return { ...state, isLoadingPregnant: true };
    case 'GET_PREGNANT_SUCCESS':
      return {
        ...state,
        pregnant: action.forumPosts,
        isLoadingPregnant: false,
        pregnantTotalCount: action.totalCount
      };
    case 'GET_PREGNANT_FAILED':
      return { ...state, pregnant: state.pregnant, isLoadingPregnant: false };

    case 'GET_PARENT_REQUEST':
      return { ...state, isLoadingParent: true };
    case 'GET_PARENT_SUCCESS':
      return {
        ...state,
        parent: action.forumPosts,
        isLoadingParent: false,
        parentTotalCount: action.totalCount
      };
    case 'GET_PARENT_FAILED':
      return { ...state, parent: state.parent, isLoadingParent: false };

    case 'GET_TOPPOST_REQUEST':
      return { ...state, topPosts: [], isLoadingTopPosts: true };
    case 'GET_TOPPOST_SUCCESS':
      return { ...state, topPosts: action.forumPosts, isLoadingTopPosts: false };
    case 'GET_TOPPOST_FAILED':
      return { ...state, topPosts: state.topPosts, isLoadingTopPosts: false };

    case 'GET_POST_BY_ID_FAILED':
      return { ...state, threadPost: null };
    case 'GET_POST_BY_ID_SUCCESS':
      return { ...state, threadPost: action.threadPost };

    case 'CREATE_POST_REQUEST':
    case 'CREATE_POST_FAILED':
    case 'CREATE_POST_SUCCESS':
      return { ...state };

    case 'SET_CURRENT_THREAD_POST':
      return { ...state, threadPost: action.threadPost };

    default:
      return state;
  }
};

interface GetPregnantRequest {
  type: 'GET_PREGNANT_REQUEST';
}
interface GetPregnantSuccess {
  type: 'GET_PREGNANT_SUCCESS';
  forumPosts: IForumPostModel[];
  totalCount: number;
}
interface GetPregnantFailed {
  type: 'GET_PREGNANT_FAILED';
}

interface GetParentRequest {
  type: 'GET_PARENT_REQUEST';
}
interface GetParentSuccess {
  type: 'GET_PARENT_SUCCESS';
  forumPosts: IForumPostModel[];
  totalCount: number;
}
interface GetParentFailed {
  type: 'GET_PARENT_FAILED';
}

interface GetTopPostsRequest {
  type: 'GET_TOPPOST_REQUEST';
}
interface GetTopPostsSuccess {
  type: 'GET_TOPPOST_SUCCESS';
  forumPosts: IForumPostModel[];
  totalCount: number;
}
interface GetTopPostsFailed {
  type: 'GET_TOPPOST_FAILED';
}

interface GetPostByIdRequest {
  type: 'GET_POST_BY_ID_REQUEST';
}
interface GetPostByIdSuccess {
  type: 'GET_POST_BY_ID_SUCCESS';
  threadPost: IForumPostModel;
}
interface GetPostByIdFailed {
  type: 'GET_POST_BY_ID_FAILED';
}

interface CreatePostRequest {
  type: 'CREATE_POST_REQUEST';
}
interface CreatePostSuccess {
  type: 'CREATE_POST_SUCCESS';
}
interface CreatePostFailed {
  type: 'CREATE_POST_FAILED';
}

interface SearchRequest {
  type: 'SEARCH_REQUEST';
}
interface SearchSuccess {
  type: 'SEARCH_SUCCESS';
  searchResults: IPagedPostResponse;
}
interface SearchFailed {
  type: 'SEARCH_FAILED';
}

interface SetCurrentThreadPost {
  type: 'SET_CURRENT_THREAD_POST';
  threadPost: IForumPostModel | RecipeModel | null;
}

export type KnownActions =
  | GetPregnantRequest
  | GetPregnantSuccess
  | GetPregnantFailed
  | GetParentRequest
  | GetParentSuccess
  | GetParentFailed
  | GetTopPostsRequest
  | GetTopPostsSuccess
  | GetTopPostsFailed
  | GetPostByIdRequest
  | GetPostByIdSuccess
  | GetPostByIdFailed
  | CreatePostRequest
  | CreatePostSuccess
  | CreatePostFailed
  | SearchRequest
  | SearchSuccess
  | SearchFailed
  | SetCurrentThreadPost;
